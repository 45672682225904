import { useEffect, useId, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RootState } from 'app/store'
import { deleteList, updateList } from 'slices/list'
import { setLoading } from 'slices/user'
import MainLayout from 'layouts/MainLayout'
import DateRangePicker from 'components/DateRangePicker'
import Search from 'components/Search'
import DeleteListModal from 'components/modal/DeleteList'

const tabs = ['Overview', 'Records', 'Settings']

const classNames = (...classes: string[]) => {
	return classes.filter(Boolean).join(' ')
}

const ListDetails = () => {
	const [nameId, descriptionId] = [useId(), useId()]
	const [open, setOpen] = useState<boolean>(false)
	const [listData, setListData] = useState<Record<any, any>>({})
	const [selectedTab, setSelectedTab] = useState(tabs[1])
	const nameRef = useRef<any>(null)
	const descriptionRef = useRef<any>(null)
	const navigate = useNavigate()
	const { id } = useParams()
	const dispatch = useAppDispatch()
	const lists = useAppSelector((state: RootState) => state.list.lists)

	useEffect(() => {
		if (!id) return
		for (let list of lists) {
			if (list.id === id) {
				setListData(list)
			}
		}
	}, [id, lists])

	const onDateRangeChange = () => {}

	const onSearch = (arg: string): void => {
		console.log(arg)
	}

	const handleOpenModal = (): void => {
		setOpen(true)
	}

	const closeModal = (): void => {
		setOpen(false)
	}

	const handleUpdateList = async (): Promise<void> => {
		dispatch(setLoading(true))

		try {
			const name = nameRef.current.value
			const description = descriptionRef.current.value

			const data = await dispatch(updateList({ id: id || '', name, description }))
			if (data.payload.success) {
				toast.success('Successfully updated')
			} else {
				toast.error(data.payload.message)
			}
		} catch (err: any) {
			toast.error(err.message)
		}

		dispatch(setLoading(false))
	}

	const onDeleteList = async (): Promise<void> => {
		dispatch(setLoading(true))

		try {
			const data = await dispatch(deleteList(id || ''))

			if (data.payload.success) {
				toast.success(`Successfully deleted ${id}`)
				navigate('/lists')
			} else {
				toast.success(data.payload.message)
			}
		} catch (err: any) {
			toast.error(err.message)
		}

		dispatch(setLoading(false))
	}

	return (
		<MainLayout title="List - Detail">
			<DeleteListModal
				open={open}
				closeModal={closeModal}
				name={listData.name}
				onDelete={onDeleteList}
			/>
			<div className="container py-4">
				<div>
					<nav aria-label="Breadcrumb">
						<ol className="flex items-center space-x-4">
							<li>
								<div className="flex">
									<Link
										to="/lists"
										className="text-sm font-medium text-gray-500 hover:text-gray-700"
									>
										Lists
									</Link>
								</div>
							</li>
							<li>
								<div className="flex items-center">
									<ChevronRightIcon
										className="h-5 w-5 flex-shrink-0 text-gray-400"
										aria-hidden="true"
									/>
									<button className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
										{id}
									</button>
								</div>
							</li>
						</ol>
					</nav>
				</div>
				<div className="flex items-end gap-4">
					<nav className="flex w-fit space-x-4 rounded-md bg-white p-1" aria-label="Tabs">
						{tabs.map((tab) => (
							<div
								className={classNames(
									tab === selectedTab
										? 'bg-indigo-100 text-indigo-700'
										: 'text-gray-500 hover:text-gray-700',
									'cursor-pointer rounded-md px-3 py-2 text-sm font-medium'
								)}
								aria-current={tab === selectedTab ? 'page' : undefined}
								onClick={() => setSelectedTab(tab)}
								key={tab}
							>
								{tab}
							</div>
						))}
					</nav>
					<Search className="ml-auto" onSearch={onSearch} />
					<DateRangePicker onChange={onDateRangeChange} />
				</div>
				<div className="mt-8">
					{selectedTab === 'Records' && (
						<div className="overflow-x-auto">
							<table className="min-w-full divide-y divide-gray-300">
								<thead>
									<tr>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											record_id
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											record_date
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											record_timestamp
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											record_data.key
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											record_data.value.string_value
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											record_data.value.int_value
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											record_source.key
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											record_source.value.string_value
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											record_source.value.int_value
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											record_offer_event.name
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											record_offer_event.campaign_id
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											record_offer_event.offer_id
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											record_offer_event.timestamp
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											record_offer_event.code
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											record_offer_event.transaction_id
										</th>

										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											dnc_list
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											phone_type
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											carrier
										</th>
										<th
											scope="col"
											className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											unsubscribe
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{new Array(10).fill(0).map((_, index: number) => (
										<tr key={index}>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>

											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
											<td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
												1
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
					{selectedTab === 'Settings' && (
						<div className="mx-auto w-full max-w-6xl rounded-md border border-gray-500 p-6">
							<div className="space-y-4">
								<div>
									<label htmlFor={nameId} className="input-label">
										Name
									</label>
									<input
										type="text"
										id={nameId}
										className="app-input w-96"
										defaultValue={listData.name}
										ref={nameRef}
									/>
								</div>
								<div>
									<label htmlFor={descriptionId} className="input-label">
										Description
									</label>
									<input
										type="text"
										id={descriptionId}
										className="app-input w-96"
										defaultValue={listData.description}
										ref={descriptionRef}
									/>
								</div>
								<button className="btn-primary" onClick={handleUpdateList}>
									Update
								</button>
							</div>
							<div className="mt-12 space-y-4">
								<p>Delete {listData.name} from Big Query</p>
								<button className="btn-primary bg-red-500" onClick={handleOpenModal}>
									Delete List
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
		</MainLayout>
	)
}

export default ListDetails
