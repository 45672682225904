import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { RootState } from 'app/store'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { getAllList, createNewList } from 'slices/list'
import MainLayout from 'layouts/MainLayout'
import CreateNewListModal from 'components/modal/CreateNewList'

const Lists = () => {
	const [open, setOpen] = useState<boolean>(false)
	const [isCreating, setIsCreating] = useState<boolean>(false)
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const lists = useAppSelector((state: RootState) => state.list.lists)

	useEffect(() => {
		dispatch(getAllList())
	}, [dispatch])

	const handleOpenModal = async (): Promise<void> => {
		setOpen(true)
	}

	const handleCreateNewList = async (
		name: string,
		description: string
	): Promise<void> => {
		setIsCreating(true)

		try {
			const data = await dispatch(createNewList({ name, description }))
			if (data.payload.success) {
				toast.success('Successfully created a new list')
				setOpen(false)
			} else {
				toast.error(data.payload.message)
			}
		} catch (_) {
			toast.error('Network Error!')
		}

		setIsCreating(false)
	}

	const handleClickList = (list: any) => {
		navigate(`/lists/${list.id}`)
	}

	return (
		<MainLayout title="Parcel - Lists">
			<CreateNewListModal
				open={open}
				closeModal={() => setOpen(false)}
				onCreateNewList={handleCreateNewList}
				loading={isCreating}
			/>
			<div className="container mx-auto max-w-7xl py-4">
				<h1 className="mt-4 border-b border-gray-300 pb-2 text-2xl font-bold leading-7 text-gray-900">
					Lists
				</h1>
				<div className="mt-4 flex items-end justify-between">
					<div className="">
						<button
							type="button"
							className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							onClick={handleOpenModal}
						>
							Create New List
						</button>
					</div>
					<button
						type="button"
						className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						onClick={() => navigate('/lists/manage-fields')}
					>
						Manage Fields
					</button>
				</div>
				<div className="mt-4 flow-root">
					<div className="overflow-x-auto bg-white shadow-md">
						<div className="inline-block min-w-full py-1 align-middle">
							<table className="min-w-full divide-y divide-gray-300">
								<thead>
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
										>
											Name
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Description
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Records
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Last Entry
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Date Created
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200">
									{lists.map((list: any, index) => (
										<tr
											className="cursor-pointer hover:opacity-80"
											onClick={() => handleClickList(list)}
											key={index}
										>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
												{list.name}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{list.description}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												0
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												April 12, 2023
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{new Date(list.created_at).toLocaleString()}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</MainLayout>
	)
}

export default Lists
