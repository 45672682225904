import { useState } from 'react'
import { Link } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import MainLayout from 'layouts/MainLayout'
import System from './System'
import Record from './Record'
import Source from './Source'
import Calculators from './Calculators'

const tabs = ['System', 'Record', 'Source', 'Calculators']

const classNames = (...classes: string[]) => {
	return classes.filter(Boolean).join(' ')
}

const ManageFields = () => {
	const [selectedTab, setSelectedTab] = useState(tabs[1])

	return (
		<MainLayout title="Parcel - Lists">
			<div className="container mx-auto mt-4 max-w-7xl py-4">
				<div>
					<nav aria-label="Breadcrumb">
						<ol className="flex items-center space-x-4">
							<li>
								<div className="flex">
									<Link
										to="/lists"
										className="text-sm font-medium text-gray-500 hover:text-gray-700"
									>
										Lists
									</Link>
								</div>
							</li>
							<li>
								<div className="flex items-center">
									<ChevronRightIcon
										className="h-5 w-5 flex-shrink-0 text-gray-400"
										aria-hidden="true"
									/>
									<button className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
										Manage Fields
									</button>
								</div>
							</li>
						</ol>
					</nav>
				</div>
				<div className="mt-4 flex items-end gap-4">
					<nav className="flex w-fit space-x-4 rounded-md bg-white p-1" aria-label="Tabs">
						{tabs.map((tab) => (
							<div
								className={classNames(
									tab === selectedTab
										? 'bg-indigo-100 text-indigo-700'
										: 'text-gray-500 hover:text-gray-700',
									'cursor-pointer rounded-md px-3 py-2 text-sm font-medium'
								)}
								aria-current={tab === selectedTab ? 'page' : undefined}
								onClick={() => setSelectedTab(tab)}
								key={tab}
							>
								{tab}
							</div>
						))}
					</nav>
				</div>
				<div className="mt-8">
					{selectedTab === tabs[0] ? (
						<System />
					) : selectedTab === tabs[1] ? (
						<Record />
					) : selectedTab === tabs[2] ? (
						<Source />
					) : (
						<Calculators />
					)}
				</div>
			</div>
		</MainLayout>
	)
}

export default ManageFields
