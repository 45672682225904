import { useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppDispatch } from 'app/hooks'
import { login } from 'slices/user'

const Login = () => {
	const emailRef = useRef<HTMLInputElement>(null)
	const passwordRef = useRef<HTMLInputElement>(null)
	const navigate = useNavigate()
	const dispatch = useAppDispatch()

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()
		const email = emailRef.current?.value || ''
		const password = passwordRef.current?.value || ''

		try {
			const data = await dispatch(login({ email, password }))

			if (data.payload.success) {
				localStorage.setItem('email', email)
				localStorage.setItem('token', data.payload.token)
				navigate('/')
			} else {
				toast.error(data.payload.message)
			}
		} catch (err: any) {
			toast.error('Network error')
		}
	}

	return (
		<section className="flex h-screen items-center justify-center bg-background">
			<div className="flex w-full max-w-lg flex-col items-center justify-center px-6 py-8">
				<Link
					to="/"
					className="mb-6 flex items-start text-3xl font-semibold text-primary"
				>
					<img className="h-8 w-8" src="/images/logo.png" alt="logo" />
					ARCEL
				</Link>
				<div className="w-full space-y-4 rounded-lg bg-white p-6 shadow-md">
					<h1 className="text-2xl font-bold">Sign in to your account</h1>
					<form className="space-y-4" autoComplete="off" onSubmit={handleSubmit}>
						<div>
							<label htmlFor="email" className="text-sm font-medium">
								Your email
							</label>
							<input
								type="email"
								name="email"
								id="email"
								placeholder="name@company.com"
								className="w-full rounded-lg border p-2.5"
								ref={emailRef}
								required
							/>
						</div>
						<div>
							<label htmlFor="password" className="text-sm font-medium">
								Password
							</label>
							<input
								type="password"
								name="password"
								id="password"
								placeholder="••••••••"
								className="w-full rounded-lg border p-2.5"
								ref={passwordRef}
								required
							/>
						</div>
						<div className="flex items-center gap-2">
							<input id="remember" type="checkbox" className="h-4 w-4" />
							<label htmlFor="remember">Remember me</label>
						</div>
						<button
							type="submit"
							className="w-full rounded-lg  bg-primary px-5 py-2.5 text-center text-sm font-medium text-white"
						>
							Login
						</button>
					</form>
				</div>
			</div>
		</section>
	)
}

export default Login
