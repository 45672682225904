import { useCallback, useId } from 'react'
import debounce from 'lodash.debounce'

interface Props {
	className?: string
	onSearch: (arg: string) => void
}

const Search: React.FC<Props> = ({ className = '', onSearch }) => {
	const id = useId()

	const changeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
		onSearch(e.target.value)
	}

	const debounceChangeHandler = useCallback(debounce(changeHandler, 300), []) //eslint-disable-line

	return (
		<div className={className}>
			<label htmlFor={id} className="input-label">
				Search
			</label>
			<input
				type="text"
				id={id}
				className="app-input w-60"
				onChange={debounceChangeHandler}
			/>
		</div>
	)
}

export default Search
