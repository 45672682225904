import axios from './axios'

export const apiGetAllLists = async (): Promise<any> => {
	try {
		const { data } = await axios.get('/list')
		return data
	} catch (_) {
		throw Error()
	}
}

export const apiCreateNewList = async (params: {
	name: string
	description: string
}): Promise<any> => {
	try {
		const { data } = await axios.post('/list', params)
		return data
	} catch (_) {
		throw Error()
	}
}

export const apiGetListDetails = async (id: string): Promise<any> => {
	try {
		const { data } = await axios.get(`/list/${id}`)
		return data
	} catch (err) {
		throw err
	}
}

export const apiUpdateList = async (params: {
	id: string
	name: string
	description: string
}): Promise<any> => {
	try {
		const { data } = await axios.put(`/list/${params.id}`, params)
		return data
	} catch (err) {
		throw err
	}
}

export const apiDeleteList = async (id: string): Promise<any> => {
	try {
		const { data } = await axios.delete(`/list/${id}`)
		return data
	} catch (err) {
		throw err
	}
}
