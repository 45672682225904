import { PropagateLoader } from 'react-spinners'
import { useAppSelector } from 'app/hooks'
import { RootState } from 'app/store'

const Loading = () => {
	const loading = useAppSelector((state: RootState) => state.user.loading)
	return (
		<div
			className={`fixed inset-0 z-[2000] flex h-screen w-screen items-center justify-center transition-all duration-300 ease-in-out ${
				loading
					? 'opacity-1 pointer-events-auto backdrop-blur-sm'
					: 'pointer-events-none opacity-0'
			}`}
		>
			<PropagateLoader color="#4f46e5" />
		</div>
	)
}

export default Loading
