import { useEffect, useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

interface Props {
	onChange: (_range: any) => void
	position?: string
}

const MyDateRangePicker = ({ onChange, position = 'left' }: Props) => {
	const [open, setOpen] = useState<boolean>(false)
	const [dateRange, setDateRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: 'date',
	})

	useEffect(() => {
		onChange(dateRange)
	}, [dateRange, onChange])

	const handleChange = (range: Record<any, any>) => {
		setDateRange(range.date)
		setOpen(false)
	}
	return (
		<div className="relative inline">
			<label className="input-label">Date Range</label>
			<button className="app-input" onClick={() => setOpen((prev) => !prev)}>
				{dateRange.startDate.toISOString().slice(0, 10)}
				{' ~ '}
				{dateRange.endDate.toISOString().slice(0, 10)}
			</button>
			{open && (
				<div
					className={`absolute bottom-0 ${
						position === 'right' ? 'left-0' : 'right-0'
					} translate-y-full border shadow-xl`}
				>
					<DateRangePicker ranges={[dateRange]} onChange={handleChange} />
				</div>
			)}
		</div>
	)
}

export default MyDateRangePicker
