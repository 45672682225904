import Helmet from 'react-helmet'

import Header from './Header'

interface Props {
	children?: React.ReactNode
	className?: string
	title?: string
}

const MainLayout = ({ children, className = '', title }: Props) => {
	return (
		<>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<div className="bg-background">
				<Header />
				<main className={`${className}`}>{children}</main>
			</div>
		</>
	)
}

export default MainLayout
