import { configureStore } from '@reduxjs/toolkit'
import list from 'slices/list'
import user from 'slices/user'

export const store = configureStore({
	reducer: {
		list,
		user,
	},
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
