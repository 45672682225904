import { PlusCircleIcon } from '@heroicons/react/20/solid'
import Toggle from 'components/Toggle'

const Record = () => {
	return (
		<div>
			<h3 className="my-2 text-lg font-bold">System Fields</h3>
			<div className="overflow-x-auto bg-white shadow-md">
				<div className="inline-block min-w-full py-1 align-middle">
					<table className="min-w-full divide-y divide-gray-300">
						<thead>
							<tr>
								<th
									scope="col"
									className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
								>
									Name
								</th>
								<th
									scope="col"
									className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
								>
									Label
								</th>
								<th
									scope="col"
									className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
								>
									Type
								</th>
								<th
									scope="col"
									className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
								>
									Token
								</th>
								<th
									scope="col"
									className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
								>
									Active Token
								</th>
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200">
							<tr>
								<td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
									First Name
								</td>
								<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
									firstName
								</td>
								<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
									string
								</td>
								<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
									<span
										className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
										dangerouslySetInnerHTML={{ __html: '{firstName}' }}
									/>
								</td>
								<td className="whitespace-nowrap py-4 px-3">
									<Toggle />
								</td>
							</tr>
							<tr>
								<td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
									<input className="app-input w-56" />
								</td>
								<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
									<input className="app-input w-56" />
								</td>
								<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
									<select className="app-input w-36">
										<option>string</option>
										<option>integer</option>
										<option>boolean</option>
									</select>
								</td>
								<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
									<span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"></span>
								</td>
								<td className="whitespace-nowrap py-4 px-3">
									<Toggle />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div className="mt-4 flex justify-between">
				<div>
					<button
						type="button"
						className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
					>
						<PlusCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
						Add Row
					</button>
				</div>
				<div>
					<button className="btn-primary">Update</button>
				</div>
			</div>
		</div>
	)
}

export default Record
