import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const ProfileMenu = () => {
	const [open, setOpen] = useState<boolean>(false)
	const navigate = useNavigate()

	const handleSingOut = () => {
		localStorage.removeItem('email')
		localStorage.removeItem('token')
		setOpen(false)
		navigate('/login')
	}

	return (
		<div className="relative">
			<img
				src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
				alt=""
				className="h-8 w-8 cursor-pointer rounded-full"
				onClick={() => setOpen((prev) => !prev)}
			/>
			{open && (
				<div className="absolute bottom-0 -right-2 translate-y-full rounded bg-white p-3 shadow-md">
					<div className="my-3 grid w-32 gap-4 text-sm text-gray">
						<p className="cursor-pointer hover:text-black">My Profile</p>
						<p className="cursor-pointer hover:text-black">Account Settings</p>
						<p className="cursor-pointer hover:text-black" onClick={handleSingOut}>
							Sign Out
						</p>
					</div>
				</div>
			)}
		</div>
	)
}

export default ProfileMenu
