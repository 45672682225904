import {useEffect, useState} from 'react'

import axios from 'utils/axios'
import {getPercentage} from 'utils'
import MainLayout from 'layouts/MainLayout'
import DateRangePicker from 'components/DateRangePicker'

const ths = [
    {label: 'Messages Sent', key: 'message_sent'},
    {label: 'Clicks', key: 'message_click'},
    {label: 'Click Rate', key: 'click_rate'},
    {label: 'Conversions', key: 'message_conversion'},
    {label: 'Sent Conversion Rate', key: 'sent_conversion_rate'},
    {label: 'Click Conversion Rate', key: 'click_conversion_rate'},
    {label: 'Unsubscribes', key: 'message_unsubscribe'},
    {label: 'Unsubscribe Rate', key: 'unsubscribe_rate'},
    {label: 'Revenue', key: 'revenue'},
    {label: 'RPMS', key: 'rpms'},
    {label: 'Errors', key: 'message_error'},
    {label: 'Error Rate', key: 'error_rate'},
]

const Reporting = () => {
    const [data, setData] = useState<Record<string, any>[]>([])
    const [rows, setRows] = useState<Record<string, any>[]>([])
    const [dimension, setDimension] = useState<string>('original_message_timestamp')
    const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'date',
    })
    const [orderBy, setOrderBy] = useState<string>('')
    const [order, setOrder] = useState<number>(1)
    const [fetching, setFetching] = useState(false)

    useEffect(() => {
        setFetching(true)
        axios
            .post('/reporting', {dateRange, dimension})
            .then((res) => setData(res.data.data))
            .catch((err) => console.log(err))
            .finally(() => setFetching(false))
    }, [dateRange, dimension])

    const handleDateRangeChange = (_dateRange: any) => {
        setDateRange(_dateRange)
    }

    const handleDimensionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setDimension(e.target.value)
    }

    const updateSearch = (arg: string) => {
        if (arg === '') return
        if (orderBy === arg) {
            setOrder((prev) => 0 - prev)
        } else {
            setOrder(1)
        }
        setOrderBy(arg)
    }

    useEffect(() => {
        let temp = data.map((row) => {
            return {
                ...row,
                click_rate: getPercentage(row.message_click, row.message_sent),
                sent_conversion_rate: getPercentage(row.message_conversion, row.message_sent),
                click_conversion_rate: getPercentage(row.message_conversion, row.message_click),
                unsubscribe_rate: getPercentage(row.message_unsubscribe, row.message_sent),
                rpms: Number((getPercentage(row.revenue, row.message_sent) / 10000).toFixed(2)),
                error_rate: getPercentage(row.message_error, row.message_sent),
            }
        })
        if (temp.length === 0 || orderBy === '') {
            setRows(temp)
            return
        }
        let sortedTemp = temp.sort((a, b) =>
            //@ts-ignore
            a[orderBy] < b[orderBy] ? 1 * order : -1 * order
        )
        setRows(sortedTemp)
    }, [data]) //eslint-disable-line

    useEffect(() => {
        console.log(orderBy, order)
        if (rows.length === 0 || orderBy === '') return
        const temp = rows.sort((a, b) => (a[orderBy] < b[orderBy] ? 1 * order : -1 * order))
        setRows([...temp])
    }, [orderBy, order]) //eslint-disable-line

    const handleRefresh = async () => {
        setFetching(true)
        axios
            .post('/reporting', {dateRange, dimension})
            .then((res) => setData(res.data.data))
            .catch((err) => console.log(err))
            .finally(() => setFetching(false))
    }

    return (
        <MainLayout title="Parcel - Reporting">
            <div className="container my-4">
                <div className="flex items-end justify-between">
                    <div>
                        <label htmlFor="reporting_dimension" className="input-label">
                            Dimension
                        </label>
                        <select id="reporting_dimension" name="Reporting Dimension" className="app-input" onChange={handleDimensionChange}>
                            <option value="original_message_timestamp">Date</option>
                            <option value="body">Body Text</option>
                            <option value="sending_number">Sending Number</option>
                            <option value="creative_id">Creative ID</option>
                            <option value="sending_platform">Sending Platform</option>
                            <option value="campaign_id">Campaign ID</option>
                        </select>
                    </div>

                    <div className=''>
                        <div className='flex items-end gap-2'>
                            <div>
                                <DateRangePicker onChange={handleDateRangeChange}/>
                            </div>
                            <div>
                                <button className="btn-primary disabled:bg-gray disabled:cursor-not-allowed disabled:bg-gray-400 " onClick={handleRefresh} disabled={fetching}>Refresh</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4 overflow-x-auto">
                    {rows.length === 0 ? (
                        <p>No data to display</p>
                    ) : (
                        <table className="w-full border bg-white text-left text-sm text-gray-500">
                            <thead className="bg-gray-100 text-xs font-bold uppercase text-gray-700">
                            <tr className="cursor-pointer border-b-2 border-b-gray-300">
                                {/* <th>No</th> */}
                                <th scope="col" className="cursor-pointer px-2 py-3 hover:opacity-75" onClick={() => updateSearch(dimension)}>
                                    <div className="flex items-center gap-2">
                                        {dimension === 'original_message_timestamp'
                                            ? 'Event Date'
                                            : dimension.replace('_', ' ')}
                                        {dimension === orderBy && (
                                            <img src="/images/up-arrow.png" alt="" className={`h-2 ${order === 1 ? 'rotate-180' : 'rotate-0'}`}/>
                                        )}
                                    </div>
                                </th>
                                {ths.map((th, index) => (
                                    <th scope="col" className="cursor-pointer px-2 py-3 hover:opacity-75" onClick={() => updateSearch(th.key)} key={index}>
                                        <div className="flex items-center gap-2">
                                            {th.label}
                                            {th.key === orderBy && th.key !== '' && (
                                                <img src="/images/up-arrow.png" alt="" className={`h-2 ${order === 1 ? 'rotate-180' : 'rotate-0'}`}/>
                                            )}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody className="text-xs">
                            {rows.map((row) => (
                                <tr className="border-b border-gray-300 first:border-t hover:bg-gray-100" key={row.event_timestamp} title={row.body}>
                                    <td className="px-2 py-3">
                                        {dimension === 'original_message_timestamp'
                                            ? row.date
                                            : row[dimension]}
                                    </td>
                                    <td className="px-2 py-3">{row.message_sent}</td>
                                    <td className="px-2 py-3">{row.message_click}</td>
                                    <td className="px-2 py-3">{row.click_rate + '%'}</td>
                                    <td className="px-2 py-3">{row.message_conversion}</td>
                                    <td className="px-2 py-3">{row.sent_conversion_rate + '%'}</td>
                                    <td className="px-2 py-3">{row.click_conversion_rate + '%'}</td>
                                    <td className="px-2 py-3">{row.message_unsubscribe}</td>
                                    <td className="px-2 py-3">{row.unsubscribe_rate + '%'}</td>
                                    <td className="px-2 py-3">$ {row.revenue / 100}</td>
                                    <td className="px-2 py-3">$ {row.rpms}</td>
                                    <td className="px-2 py-3">{row.message_error}</td>
                                    <td className="px-2 py-3">{row.error_rate + '%'}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </MainLayout>
    )
}

export default Reporting
