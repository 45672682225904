import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { toast } from 'react-toastify'

interface Props {
	open: boolean
	closeModal: () => void
	onCreateNewList: (name: string, description: string) => void
	loading: boolean
}

const CreateNewListModal: React.FC<Props> = ({
	open,
	closeModal,
	onCreateNewList,
	loading,
}) => {
	const nameRef = useRef<any>(null)
	const descriptionRef = useRef<any>(null)

	const handleClick = (): void => {
		if (nameRef.current.value.length >= 1 && descriptionRef.current.value.length >= 1) {
			onCreateNewList(nameRef.current.value, descriptionRef.current.value)
			return
		}

		toast.error('Invalid params')
	}

	const handleClose = (): void => {
		if (loading) return
		closeModal()
	}

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-50" onClose={handleClose}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative my-8 w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all ">
								<div>
									<Dialog.Title
										as="h3"
										className="text-base font-semibold leading-6 text-gray-900"
									>
										Create a new list
									</Dialog.Title>
									<div className="mt-4">
										<div>
											<span className="text-sm">Name</span>
											<input className="app-input mt-0" ref={nameRef} />
										</div>
										<div className="mt-2">
											<span className="text-sm">Description</span>
											<input className="app-input mt-0" ref={descriptionRef} />
										</div>
									</div>
								</div>
								<div className="mt-6">
									<button
										type="button"
										className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
										onClick={handleClick}
										disabled={loading}
									>
										Create
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default CreateNewListModal
