import axios from './axios'

export const apiLogin = async (params: { email: string; password: string }) => {
	try {
		const { data } = await axios.post('/auth/login', params)
		return data
	} catch (err) {
		throw err
	}
}
