import { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import axios from 'utils/axios'
import Login from 'pages/Auth/Login'
import Dashboard from 'pages/Dashboard'
import Campaigns from 'pages/Campaigns'
import Reporting from 'pages/Reporting'
import Lists from 'pages/Lists'
import ManageFields from 'pages/Lists/ManageFields'
import Logs from 'pages/Logs'
import ListDetails from 'pages/Lists/Details'

const AppRoutes = () => {
	const navigate = useNavigate()
	const { pathname } = useLocation()

	useEffect(() => {
		if (pathname.includes('/login')) return
		const email = localStorage.getItem('email') || ''
		const token = localStorage.getItem('token') || ''
		if (email === '' || token === '') {
			navigate('/login')
			return
		}
		axios
			.post('/auth/verify-token', { email: localStorage.getItem('email') })
			.then()
			.catch((err) => {
				console.log(err)
				navigate('/login')
			})
	}, [pathname]) //eslint-disable-line

	return (
		<>
			<Routes>
				<Route path="/" element={<Navigate to="/dashboard" replace />} />
				<Route path="/login" element={<Login />} />
				<Route path="dashboard">
					<Route index element={<Dashboard />} />
				</Route>
				<Route path="campaigns">
					<Route index element={<Campaigns />} />
				</Route>
				<Route path="reporting">
					<Route index element={<Reporting />} />
				</Route>
				<Route path="lists">
					<Route index element={<Lists />} />
					<Route path=":id" element={<ListDetails />} />
					<Route path="manage-fields">
						<Route index element={<ManageFields />} />
					</Route>
				</Route>
				<Route path="logs">
					<Route index element={<Logs />} />
				</Route>
			</Routes>
		</>
	)
}

export default AppRoutes
