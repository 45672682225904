import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {
	apiGetAllLists,
	apiCreateNewList,
	apiGetListDetails,
	apiUpdateList,
	apiDeleteList,
} from 'utils/list'

interface IList {
	lists: any[]
	listDetails: any
}

const initialState: IList = {
	lists: [],
	listDetails: null,
}

export const getAllList = createAsyncThunk('list/getAllList', async () => {
	try {
		const data = await apiGetAllLists()
		return data
	} catch (_) {
		throw Error()
	}
})

export const createNewList = createAsyncThunk(
	'list/createNewList',
	async (params: { name: string; description: string }): Promise<any> => {
		try {
			const data = await apiCreateNewList(params)
			return data
		} catch (_) {
			throw Error()
		}
	}
)

export const getListDetails = createAsyncThunk(
	'list/getListDetails',
	async (id: string): Promise<any> => {
		try {
			const data = await apiGetListDetails(id)
			return data
		} catch (_) {
			throw Error()
		}
	}
)

export const updateList = createAsyncThunk(
	'list/updateList',
	async (params: { id: string; name: string; description: string }): Promise<any> => {
		try {
			const data = await apiUpdateList(params)
			return data
		} catch (err) {
			throw err
		}
	}
)

export const deleteList = createAsyncThunk(
	'list/deleteList',
	async (id: string): Promise<any> => {
		try {
			const data = await apiDeleteList(id)
			return data
		} catch (err) {
			throw err
		}
	}
)

export const list = createSlice({
	name: 'list',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getAllList.fulfilled, (state, action: PayloadAction<any>) => {
			if (action.payload.success) {
				state.lists = action.payload.lists
			}
		})

		builder.addCase(getAllList.rejected, (state) => {
			state.lists = []
			throw Error()
		})

		builder.addCase(createNewList.fulfilled, (state, action: PayloadAction<any>) => {
			if (action.payload.success) {
				state.lists = action.payload.lists
			}
		})

		builder.addCase(createNewList.rejected, (state, action: PayloadAction<any>) => {
			throw Error()
		})

		builder.addCase(getListDetails.fulfilled, (state, action: PayloadAction<any>) => {
			if (action.payload.success) {
				state.listDetails = null
			}
		})

		builder.addCase(getListDetails.rejected, (state, action: PayloadAction<any>) => {
			throw Error()
		})

		builder.addCase(updateList.fulfilled, (state, action: PayloadAction<any>) => {
			if (action.payload.success) {
				state.lists = action.payload.lists
			}
		})

		builder.addCase(deleteList.fulfilled, (state, action: PayloadAction<any>) => {
			if (action.payload.success) {
				state.lists = action.payload.lists
			}
		})
	},
})

export default list.reducer
