import { Link, useLocation } from 'react-router-dom'
import menu from 'config/menu'
import ProfileMenu from 'components/ProfileMenu'

const Header = () => {
	const { pathname } = useLocation()

	return (
		<header className="sticky top-0 z-40 bg-white shadow-md">
			<div className="container">
				<div className="flex h-16 items-center gap-8">
					<div className="mr-auto flex items-end text-2xl font-bold leading-none text-primary">
						<img src="/images/logo.png" alt="" className="h-8 w-8" />
						ARCEL
					</div>
					<nav className="flex gap-8">
						{menu.map((item) => (
							<Link
								to={item.to}
								className={`${
									pathname.includes(item.to) ? 'text-primary' : ''
								} font-medium`}
								key={item.label}
							>
								{item.label}
							</Link>
						))}
					</nav>
					<ProfileMenu />
				</div>
			</div>
		</header>
	)
}

export default Header
