import { useEffect, useState } from 'react'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'

import axios from 'utils/axios'
import MainLayout from 'layouts/MainLayout'
import DateRangePicker from 'components/DateRangePicker'

const Logs = () => {
	const [data, setData] = useState<Record<string, any>[]>([])
	const [dateRange, setDateRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: 'date',
	})
	const [keyword, setKeyword] = useState<string>('')
	const [eventName, setEventName] = useState<string>('')
	const [pageCount, setPageCount] = useState<number>(1)
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [fetching, setFetching] = useState<boolean>(false)

	const handleDateRangeChange = (_dateRange: any) => {
		setDateRange(_dateRange)
	}

	useEffect(() => {
		handleSearch()
	}, [currentPage]) //eslint-disable-line

	const handleSearch = async () => {
		setFetching(true)
		try {
			const res = await axios.post('/logs/message-events', {
				dateRange,
				keyword,
				eventName,
				currentPage,
			})
			setData(res.data.data)
			setPageCount(res.data.pageCount)
			if (res.data.pageCount === 0) {
				setData([...[]])
			}
		} catch (err) {
			console.log(err)
		}
		setFetching(false)
	}

	const handlePageChange = (page: number) => {
		setCurrentPage(page)
	}

	return (
		<MainLayout title="Parcel - Logs">
			<div className="container my-4">
				<div className="flex items-end justify-between">
					<div className="">
						<div className="flex items-end gap-2">
							<div className="">
								<label htmlFor="log_filter" className="input-label">
									Event name
								</label>
								<select
									id="log_filter"
									className="app-input"
									onChange={(e) => setEventName(e.target.value)}
								>
									<option value="">All</option>
									<option value="message_sent">message_sent</option>
									<option value="message_error">message_error</option>
									<option value="message_unsubscribe">message_unsubscribe</option>
									<option value="message_click">message_click</option>
									<option value="message_conversion">message_conversion</option>
								</select>
							</div>

							<div className="">
								<label htmlFor="log_search" className="input-label">
									Search
								</label>
								<input
									type="text"
									id="log_search"
									className="app-input w-60"
									onChange={(e) => setKeyword(e.target.value)}
								/>
							</div>
							<div>
								<button
									className="btn-primary disabled:bg-gray disabled:cursor-not-allowed"
									onClick={handleSearch}
									disabled={fetching}
								>
									Search
								</button>
							</div>
						</div>
					</div>
					<div>
						<DateRangePicker onChange={handleDateRangeChange} />
					</div>
				</div>
				<div className="flex justify-end">
					<div className="mt-4">
						<Pagination
							current={currentPage}
							onChange={handlePageChange}
							total={pageCount}
							pageSize={1}
							className=""
						/>
					</div>
				</div>

				<div className="mt-4 overflow-x-auto">
					{data.length === 0 ? (
						<p>No data to display</p>
					) : (
						<table className="w-full border bg-white text-left text-sm text-gray-500">
							<thead className="bg-gray-100 text-xs font-bold uppercase text-gray-700">
								<tr className="border-b-2 border-b-gray-300">
									{/* <th>No</th> */}
									<th scope="col" className="px-2 py-3">
										event date
									</th>
									<th scope="col" className="px-2 py-3">
										event timestamp
									</th>
									<th scope="col" className="px-2 py-3">
										event name
									</th>
									<th scope="col" className="px-2 py-3">
										message id
									</th>
									<th scope="col" className="px-2 py-3">
										sending platform
									</th>
									<th scope="col" className="px-2 py-3">
										platform id
									</th>
									<th scope="col" className="px-2 py-3">
										modem id
									</th>
									<th scope="col" className="px-2 py-3">
										integration id
									</th>
									<th scope="col" className="px-2 py-3">
										sending number
									</th>
									<th scope="col" className="px-2 py-3">
										sending carrier
									</th>
									<th scope="col" className="px-2 py-3">
										receiving number
									</th>
									<th scope="col" className="px-2 py-3">
										receiving carrier
									</th>
									<th scope="col" className="px-2 py-3">
										creative id
									</th>
									<th scope="col" className="px-2 py-3">
										campaign id
									</th>
									<th scope="col" className="px-2 py-3">
										link id
									</th>
									<th scope="col" className="px-2 py-3">
										error id
									</th>
									<th scope="col" className="px-2 py-3">
										error name
									</th>
									<th scope="col" className="px-2 py-3">
										offer id
									</th>
									<th scope="col" className="px-2 py-3">
										offer name
									</th>
									<th scope="col" className="px-2 py-3">
										offer revenue
									</th>
								</tr>
							</thead>
							<tbody className="text-xs">
								{data.map((row) => (
									<tr
										className="border-b border-gray-300 first:border-t hover:bg-gray-100"
										key={row.event_timestamp}
										title={row.body}
									>
										<td className="whitespace-nowrap px-2 py-3">
											{new Date(+row.event_timestamp).toLocaleString()}
										</td>
										<td className="px-2 py-3">{row.event_timestamp}</td>
										<td className="px-2 py-3">{row.event_name}</td>
										<td className="px-2 py-3">{row.message_id}</td>
										<td className="px-2 py-3">{row.sending_platform}</td>
										<td className="px-2 py-3">{row.platform_id}</td>
										<td className="px-2 py-3">{row.modem_id}</td>
										<td className="px-2 py-3">{row.integration_id}</td>
										<td className="px-2 py-3">{row.sending_number}</td>
										<td className="px-2 py-3">{row.sending_carrier}</td>
										<td className="px-2 py-3">{row.receiving_number}</td>
										<td className="px-2 py-3">{row.receiving_carrier}</td>
										<td className="px-2 py-3">{row.creative_id}</td>
										<td className="px-2 py-3">{row.campaign_id}</td>
										<td className="px-2 py-3">{row.link_id}</td>
										<td className="px-2 py-3">{row.error_id}</td>
										<td className="px-2 py-3">{row.error_name}</td>
										<td className="px-2 py-3">{row.offer_id}</td>
										<td className="px-2 py-3">{row.offer_name}</td>
										<td className="px-2 py-3">{row.offer_revenue}</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
				</div>
			</div>
		</MainLayout>
	)
}

export default Logs
